import React from 'react';
import styled from 'styled-components';
import { Container } from '../Containers';

const Wrapper = styled.div`
  background-color: #605e5e;
  height: 200px;
  color: white;
  text-align: right;
`;

const Footer = () => (
  <Wrapper id="Footer">
      <Container>© Jeriel Products 2020</Container>
  </Wrapper>
);

export default Footer;
