import React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { Container } from '../Containers';
import Title from '../../components/Title';

const Wrapper = withTheme(styled.div`
  position: relative;
  z-index: 2;

  ${props => props.theme.props.isMobile ? `
    margin-top: -68px;
    padding-top: 32px;
  ` : `
    margin-top: -150px;
    padding-top: 150px;
    height: 800px;
  `}
`);

const Subtitle = styled.div`
  padding-bottom: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 18px;
`;

const Section = styled.div`
  padding-top: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
`;
const AddressContent = styled.div`

`;
const Address = styled.div`
  padding-bottom: 32px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  color: #555;
`;
const Map = styled.div`

`;
const FormContent = styled.div`
  width: 300px;
  margin-bottom: 20px;
`;

const Contact = (props) => (
  <Wrapper id="Contact">
    <Container>
      <Title variant="h4">CONTACT US</Title>
      <Section>
        <AddressContent>
          <Subtitle>ADDRESS</Subtitle>
          <Address>
            <div>155 Poag Crossing</div>
            <div>Hiram, GA 30141</div>
          </Address>
          <Map>
            <iframe
              title="mxGMTitleForAddress"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.454835034891!2d-84.75002658390213!3d33.87793808065381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f53ac6854b9c31%3A0x118d66a32d40c857!2s155%20Poag%20Crossing%2C%20Hiram%2C%20GA%2030141!5e0!3m2!1sen!2sus!4v1600014309519!5m2!1sen!2sus"
              width={props.theme.props.isMobile ? '100%' : '450px'}
              height="300"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0">
            </iframe>
          </Map>
        </AddressContent>
        <FormContent>
          <Subtitle>
            <div>TO SPEAK WITH AN AGENT,</div>
            <div>PLEASE CALL OR EMAIL US:</div>
          </Subtitle>
          <Typography>Email: orders@jerielproducts.com</Typography>
          <Typography>Tel: 770.445.2389</Typography>
        </FormContent>
      </Section>
    </Container>
  </Wrapper>
);

export default withTheme(Contact);
