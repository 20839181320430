import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { Container } from '../Containers';
import measureImg from '../../images/about-bg.png';
import corbels from '../../images/corbels.png';
import gable from '../../images/house-gable.png';
import Title from '../../components/Title';

const Wrapper = withTheme(styled.div`
  padding-bottom: 45px;
  position: relative;
  z-index: 4;

  ${props => props.theme.props.isMobile ? `
    margin-top: -68px;
    padding-top: 32px;
  ` : `
    margin-top: -150px;
    padding-top: 150px;
  `}
`);
const BgContainer = withTheme(styled.div`
  background: url(${measureImg}) 0 0 no-repeat;
  background-size: cover;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(64, 64, 64, 0.70);
    z-index: 0;
  }
  ${props => props.theme.props.isMobile ? `
    height: auto;
    margin-top: 2px;
  ` : `
    height: 375px;
  `}
`);

const Body = withTheme(styled.div`
  color: white;
  letter-spacing: 1.3px;
  line-height: 1.3;

  ${props => props.theme.props.isMobile ? `
    font-size: 16px;
  ` : `
    width: 500px;
    font-size: 20px;
  `}
`);
const LeftImg = withTheme(styled.img`
  ${props => props.theme.props.isMobile ? `
    border-radius: 4px;
    box-shadow: 0 1px 2px #0000002e;
    width: 35%;
    margin: -40px 15px 0 -20px;
    align-self: flex-start;
  ` : `
    margin-top: -70px;
  `}
`);
const RightImg = withTheme(styled.img`
  ${props => props.theme.props.isMobile ? `
    border-radius: 4px;
    box-shadow: 0 1px 2px #0000002e;
    width: 35%;
    margin: -40px -20px 0 15px;
    align-self: flex-start;
  ` : `
    margin-bottom: -50px;
    height: 400px;
  `}
`);
const MainBody = withTheme(styled.div`
  padding-top: 20px;
  line-height: 1.5;
  color: #555555;
  font-size: 18px;
  ${props => props.theme.props.isMobile ? `
    padding-bottom: 40px;
  ` : `
    height: 275px;
  `}

`);

const LeftContainer = withTheme(styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  ${props => props.theme.props.isMobile ? `
    padding-bottom: 40px;
  ` : `
    align-items: center;
  `}
`);
const RightContainer = withTheme(styled(Container)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  ${props => props.theme.props.isMobile ? `
    padding-bottom: 40px;
  ` : `
    align-items: center;
  `}
`);

const About = () => (
  <Wrapper id="About">
    <Container>
      <Title variant="h4">ABOUT</Title>
      <MainBody>
      Jeriel Products, LLC, is a family owned and operated company
      proudly serving the Southeast since 2012. Specializing in architectural
      accents, we provide quality products including columns, shutters, vents,
      brackets and corbels. Our number one goal is not just to build and sell
      quality products to our customers but to become a vital part of their
      project team. The managing partners have a combined 70 years of
      experience in the construction industry. This experience allows Jeriel
      Products to manufacture quality products that add curb appeal to your
      project. Whether it is one of our standard products or one manufactured
      per your specifications, we will strive to meet your individual needs.
      You may purchase our products as a wholesale vendor or through a
      retailer in the Southeast.
      </MainBody>
    </Container>
    <BgContainer>
      <LeftContainer>
        <LeftImg src={gable} />
        <Body>
        Jeriel Products provides high quality building accents and finishes.
        Whether it’s quality crafted columns or traditional board and batten
        shutters, we can meet your needs.
        </Body>
      </LeftContainer>
    </BgContainer>
    <BgContainer>
      <RightContainer>
        <RightImg src={corbels}/>
        <Body>
        All our products are customizable and available in a variety of materials
        and finishes. By utilizing state of the art equipment and production
        methods, we are able to deliver quality items at a reasonable cost.
        Contact us today with your needs and let us work with you to deliver a
        stylish solution to your next building project. we look forward to
        working with you.
        </Body>
      </RightContainer>
    </BgContainer>
  </Wrapper>
);

export default About;
