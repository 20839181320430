import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const Title = styled(Typography)`
  && {
    padding-top: 60px;
    color: #e21e21;
  }
`;

export default Title;
