import React, { useState } from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import UnstyledModal from '@material-ui/core/Modal';
import UnstyledPaper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Container } from '../Containers';
import ProductDetail from './ProductDetail';
import Title from '../../components/Title';
// images
import brownHouse from '../../images/brown-house.png';
import customColumnsShuttersBrackets from '../../images/custom-columns-shutters-brackets.png';
import customPvcBrackets from '../../images/custom-pvc-brackets.png';
import customPvcBrackets2 from '../../images/custom-pvc-brackets-2A.png';
import customPvcWork from '../../images/custom-pvc-work-A.png';
import customWindowHeaders2 from '../../images/custom-window-headers-2.png';
import customBarnDoor from '../../images/custom-barn-door-A.png';

import plBrackets from '../../images/product-list-brackets.png';
import plColumns from '../../images/product-list-columns.png';
import plCustom from '../../images/product-list-custom.png';
import plGables from '../../images/product-list-gables.png';
import plShutters from '../../images/product-list-shutters.png';

import bcb1 from '../../images/bcb-1.png';
import bcb2 from '../../images/bcb-2.jpg';
import bcb3 from '../../images/bcb-3.JPG';
import bcb4 from '../../images/bcb-4.jpg';
import bcb5 from '../../images/bcb-5.JPG';

import columns1 from '../../images/columns-1.png';
import shutter1 from '../../images/shutter-1.png';

import gables1 from '../../images/gables-1.png';
import gables2 from '../../images/gables-2.png';
import gables3 from '../../images/gables-3.png';
import gables4 from '../../images/gables-4.png';
import gables5 from '../../images/gables-5.png';
import gables6 from '../../images/gables-6.png';
// docs
import docBraceStyles from '../../documents/brace-styles.pdf';
import docBracketStyles from '../../documents/bracket-styles.pdf';
import docClassicColumns from '../../documents/classic-columns.pdf';
import docCorbelStyles from '../../documents/corbel-styles.pdf';
import docGableVentStyles from '../../documents/gable-vent-styles.pdf';
import docShutterStyles from '../../documents/shutter-styles.pdf';
import docTaperedColumns from '../../documents/tapered-columns.pdf';
import docWrapsColumns from '../../documents/wraps-columns.pdf';

const Wrapper = withTheme(styled.div`
  position: relative;
  z-index: 3;

  ${props => props.theme.props.isMobile ? `
    margin-top: -68px;
    padding-top: 32px;
  ` : `
    margin-top: -150px;
    padding-top: 150px;
  `}
`);
const DetailWrapper = withTheme(styled.div`
  background-color: #e9e9e9;
  ${props => props.theme.props.isMobile ? `
  ` : `
    min-height: 740px;
  `}
`);
const ProductImg = withTheme(styled.div`
  margin: 8px;
  border-radius: 6px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  display: flex;
  align-items: flex-end;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  ${props => props.theme.props.isMobile ? `
    height: 220px;
    width: 220px;
  ` : `
    height: 335px;
    width: 305px;
  `}
  ${props => props.theme.props.isMobileXs ? `
    width: 100%;
    max-height: 335px;
    max-width: 305px;
    height: auto;
  ` : ``}
`);

const ProductTitle = withTheme(styled.div`
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  color: white;
  box-sizing: border-box;
  padding: 12px 20px;
  border-radius: 0 0 6px 6px;
  letter-spacing: 1.2px;

  ${props => props.theme.props.isMobile ? `
  height: 100px;
  background-color: rgb(62 62 62 / 0.5);
  ` : `
  height: 70px;
  background-color: rgb(62 62 62 / 0.7);
  `}
`);

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const MainBody = styled.div`
  padding-top: 20px;
  line-height: 1.5;
  color: #555555;
  font-size: 18px;
`;

const Line = styled.div`
  margin-top: 10px;
  font-size: 14px;
  letter-spacing: .7px;
`;

const Modal = styled(UnstyledModal)`
  margin: 40px 20px;
`;

const Paper = styled(UnstyledPaper)`
  height: 100%;
`;

const ActionBar = styled.div`
  text-align: right;
  border-bottom: 1px solid #
`;

const products = [
  {
    name: 'BRACKETS, CORBELS &\u00A0BRACES',
    image: plBrackets,
    description: (
      <div>
        <Line>Gable brackets, corbels and braces are an excellent addition to the exterior of the home. They can also be used on commercial projects to add architectural interest and details.</Line>
        <Line>These handcrafted pieces look great in free standing structures such as gazebos and pergolas and are a perfect way to dress up porches and entrances. These architectural elements will add dramatic character to your building project.</Line>
        <Line>AVAILABLE IN WESTERN RED CEDAR, PRESSURE TREATED AND PVC. OTHER MATERIAL AVAILABLE UPON REQUEST.</Line>
      </div>
    ),
    slideImages: [
      {
        url: bcb1,
        text: '',
      },
      {
        url: bcb2,
        text: '',
      },
      {
        url: bcb5,
        text: '',
      },
      {
        url: bcb3,
        text: '',
      },
      {
        url: bcb4,
        text: '',
      },
    ],
    pdf: [
      {
        name: 'BRACKET STYLES',
        file: docBracketStyles
      },
      {
        name: 'CORBEL STYLES',
        file: docCorbelStyles
      },
      {
        name: 'BRACE STYLES',
        file: docBraceStyles
      },
    ],
  },
  {
    name: 'COLUMNS',
    image: plColumns,
    description: (
      <div>
        <Line>Hand crafted, factory seamed-columns provide a superior finish with no joint separation due to expansion. Our columns are manufactured with 100% PVC materials for low-maintenance and no rotting. They are engineered to with-stand even the toughest elements. We offer sleek designs and simple installation. All of our columns can be manufactured to any specification you may require. </Line>
        <Line>*Columns can also be made from cedar</Line>
      </div>
    ),
    slideImages: [
      {
        url: columns1,
        text: '',
      },
    ],
    pdf: [
      {
        name: 'CLASSIC',
        file: docClassicColumns
      },
      {
        name: 'TAPERED',
        file: docTaperedColumns
      },
      {
        name: 'WRAPS',
        file: docWrapsColumns
      },
    ],
  },
  {
    name: 'SHUTTERS',
    image: plShutters,
    description: 'The right exterior shutter can reflect your own distinctive style and dramatically increase your home’s curb appeal. It is the first thing you and your visitors see.With a wide variety of styles in standard and custom sizes, we can help you create that exciting “Welcome Home” moment, with custom shutters by Jeriel Products.',
    slideImages: [
      {
        url: shutter1,
        text: '',
      },
    ],
    pdf: [
      {
        name: 'STYLE OPTIONS',
        file: docShutterStyles,
      }
    ],
  },
  {
    name: 'GABLE VENTS',
    image: plGables,
    description: (
      <div>
        <Line>Designed to be both beautiful and functional, gable vents (or louvers) have been used as design accents. We offer two basic types: functional (venting) and decorative (non-venting). They come in a multitude of materials and shapes to fit most architectural styles. Unlike other passive venting systems, such as soffit or ridge vents, gable vents are meant to be seen. They are made visible in the front of a building and add an architectural element to an otherwise invisible opening. </Line>
        <Line>*Vent materials: cedar, PVC, pine and primed</Line>
      </div>
    ),
    slideImages: [
      {
        url: gables1,
        text: '',
      },
      {
        url: gables2,
        text: '',
      },
      {
        url: gables3,
        text: '',
      },
      {
        url: gables4,
        text: '',
      },
      {
        url: gables5,
        text: '',
      },
      {
        url: gables6,
        text: '',
      },
    ],
    pdf: [
      {
        name: 'STYLE OPTIONS',
        file: docGableVentStyles,
      }
    ],
  },
  {
    name: 'CUSTOM WORK',
    image: plCustom,
    description: (
      <div>
        <Line>Looking to add a custom touch to your building project to make it stand out from others? Don’t see exactly what you need or want? No problem! Jeriel Products offers a wide variety of custom, made to order designs. Have a photo, drawing or just an idea? Let our team of skilled craftsmen work with you to bring it to life.</Line>
        <Line>PLEASE SCROLL THROUGH IMAGES TO SEE SOME OF OUR CUSTOM WORK</Line>
      </div>
    ),
    slideImages: [
      {
        url: brownHouse,
        text: 'BEAUTIFULLY DESIGNED PORCH WITH COLUMNS TO MATCH THE STYLE',
      },
      {
        url: customPvcBrackets,
        text: 'A UNIQUE PVC BRACKET DESIGN TO COMPLIMENT THE OTHER PVC ACCENTS OF THE HOME',
      },
      {
        url: customWindowHeaders2,
        text: 'WE CAN CUSTOMIZE ANY WINDOW TO SHOW INDIVIDUAL STYLE',
      },
      {
        url: customColumnsShuttersBrackets,
        text: "CUSTOM DESIGNED CEDAR COLUMNS, SHUTTERS AND BRACKETS ACCENT THE HOME'S STYLE",
      },
      {
        url: customPvcBrackets2,
        text: 'A UNIQUE BRACKET DESIGN TO GO WITH THE HOMEOWNER STYLE',
      },
      {
        url: customPvcWork,
        text: 'USING PVC TO DESIGN BEAUTIFUL DOORWAYS',
        styles: {
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#000000eb',
        }
      },
      {
        url: customBarnDoor,
        text: 'THIS CUSTOM DESIGNED DOOR WAS HAND CRAFTED FROM PVC AND PINE TO ACHIEVE A UNIQUE, MODERN LOOK',
        styles: {
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#000000eb',
        }
      },
    ],
    pdf: []
  },
];
const Products = (props) => {
  const [product, setProduct] = useState(null);
  const [open, setOpen] = useState(false);
  const onProductImgClick = (product) => {
    setProduct(product);
    setOpen(true);
  };
  const onProductDetailClose = () => {
    setOpen(false);
  };

  return (
    <Wrapper id="Products">
      <Container>
        <Title variant="h4">PRODUCTS</Title>
        <MainBody>Learn more about our products as high quality building accents and finishes.</MainBody>
      </Container>
      <DetailWrapper>
        <Container>
          <Grid>
            {products.map((p,i) => (
              <ProductImg
                key={`${p.image}-${i}`}
                style={{
                  'backgroundImage': `url(${p.image})`
                }}
                onClick={() => onProductImgClick(p)}
              >
                <ProductTitle>{p.name}</ProductTitle>
              </ProductImg>
            ))}
          </Grid>
        </Container>
      {(open && !props.theme.props.isMobile ) &&
        <ProductDetail onClose={onProductDetailClose} product={product} />
      }
      {props.theme.props.isMobile &&
        <Modal
          open={open}
          onClose={onProductDetailClose}
        >
          <Paper>
            <ActionBar><IconButton onClick={onProductDetailClose} component="span"><CloseIcon/></IconButton></ActionBar>
            <ProductDetail onClose={onProductDetailClose} product={product} />
          </Paper>
        </Modal>
      }
      </DetailWrapper>
    </Wrapper>
  );
};

export default withTheme(Products);
