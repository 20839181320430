import React from 'react';
import styled from 'styled-components';
import { StickyContainer } from 'react-sticky';
import Header from './containers/Header';
import About from './containers/About';
import Contact from './containers/Contact';
import Hero from './containers/Hero';
import Products from './containers/Products';
import Footer from './containers/Footer';

const AppWrapper = styled.div`
  position: relative;
  height: 100%;
`;
const AppBody = styled.div`
  height: 100%;
`;

function App() {
  return (
    <StickyContainer>
    <AppWrapper>
      <Header />
      <AppBody>
        <Hero />
        <About />
        <Products />
        <Contact />
        <Footer />
      </AppBody>
    </AppWrapper>
    </StickyContainer>
  );
}

export default App;
