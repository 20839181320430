import React from 'react';
import styled from 'styled-components';
import UnstyledList from '@material-ui/core/List';
import { NavHashLink  as UnstyledNavLink } from 'react-router-hash-link';
import { Sticky } from 'react-sticky';
import logo from '../../images/jeriel-products-logo.png';
import { Container } from '../Containers';
import { withTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

const List = withTheme(styled(UnstyledList)`
  display: flex;
  width: 100%;
  ${props => props.theme.props.isMobile ? `
    flex-direction: column;
  ` : `
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 600px;
  `};
`);
const Link = withTheme(styled(UnstyledNavLink)`
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: #e21e21;
  box-sizing: border-box;
  &:hover{
    color: #555555;
  }
  ${props => props.theme.props.isMobile ? `
    min-width: 175px;
    font-size: 16px;
    padding: 16px;
    border-top: 1px solid #f2f2f2;
  ` : ``};
`);
const Wrapper = styled.div`
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.60);
`;
const Logo = withTheme(styled.div`
  display: flex;
  flex-direction: ${props => props.theme.props.isMobile ? 'row' : 'column'};
  align-items: center;
`);
const LogoImg = withTheme(styled.img`
  ${props => props.theme.props.isMobile ? `
    width: 40px;
    margin-right: 8px;
  ` : `
    width: 80px;
  `};

`);
const LogoTitle = styled.div`
  color: #e21e21;
  padding-top: 8px;
  font-size: 14px;
  font-weight: bold;
`;
const HeaderContainer = withTheme(styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  ${props => props.theme.props.isMobile ? `
    height: auto;
    padding: 10px 20px;
  ` : `
    height: 150px;
  `};
`);

const Nav = (props) => (
  <List
    component="nav"
    {...props}
  >
    <Link smooth to="#About" isActive={() => false} >
        ABOUT
    </Link>
    <Link smooth to="#Products" isActive={() => false} >
        PRODUCTS
    </Link>
    <Link smooth to="#Contact" isActive={() => false} >
        CONTACT US
    </Link>
  </List>
);

const Header = (props) => {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <>
      <Sticky>
      {({ style }) => (
        <Wrapper style={style}>
          <HeaderContainer >
            <Logo >
              <LogoImg  src={logo} />
              <LogoTitle variant="body2">JERIEL PRODUCTS</LogoTitle>
            </Logo>
            {!props.theme.props.isMobile ? (
              <Nav/>
            ) : (
              <IconButton onClick={toggleDrawer} component="span"><MenuIcon /></IconButton>
            )}
          </HeaderContainer>
        </Wrapper>
      )}
      </Sticky>
      <Drawer anchor="right" open={open} onClose={toggleDrawer}>
        <div><IconButton onClick={toggleDrawer} component="span"><CloseIcon/></IconButton></div>
        <Nav
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        />
      </Drawer>
    </>
  );
}
export default withTheme(Header);
