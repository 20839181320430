import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const ThemeMod = (props) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const [isMobileXs, setIsMobileXs] = React.useState(false);
  const theme = createMuiTheme({
    typography: {
      fontFamily: 'Avenir, Roboto, Helvetica, Arial, sans-serif',
    },
    props: {
      isMobile,
      isMobileXs
    }
  });
  const mobileQuery = useMediaQuery("@media (max-width:900px)");
  const mobileQueryXs = useMediaQuery(theme.breakpoints.only("xs"));
  function handleResize() {
    if(mobileQuery !== isMobile){
      setIsMobile(mobileQuery);
    }
    if(mobileQueryXs !== isMobileXs){
      setIsMobileXs(mobileQueryXs);
    }
  };
  React.useEffect(() => {
    return handleResize();
  }, [mobileQuery, mobileQueryXs]);
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeMod>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeMod>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
