import React from 'react';
import styled from 'styled-components';
import { Slide } from 'react-slideshow-image';
import PhoneIcon from '@material-ui/icons/Phone';
import GetAppIcon from '@material-ui/icons/GetApp';
import { withTheme } from '@material-ui/core/styles';
import 'react-slideshow-image/dist/styles.css';
import greenHouse from '../../images/green-house-3.jpg';
import blueHouse from '../../images/blue-house-reversed.jpg';
import brownHouse from '../../images/brown-house.png';
import brochure from '../../documents/brochure.pdf';
import { Container } from '../Containers';

const Wrapper = withTheme(styled.div`
  height: 800px;
  position: relative;
  z-index: 5;
  background-color: white;
  ${props => props.theme.props.isMobile ? `
    height: auto;
  ` : ``}
`);
const HeroContainer = withTheme(styled(Container)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top:0;

  ${props => props.theme.props.isMobile ? `
    position: relative;
    padding: 0;
  ` : ``}
`);
const StyledPhoneIcon = withTheme(styled(PhoneIcon)`
  margin-right: 10px;
  ${props => props.theme.props.isMobileXs ? `
  ` : `
    margin-left: 20px;
  `}
`);

const Banner = withTheme(styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(197, 37, 40, 0.91);
  width: 350px;
  box-sizing: border-box;
  padding: 20px;
  height: 435px;

  ${props => props.theme.props.isMobile ? `
    position: relative;
    width: auto;
    height: auto;
    margin: 20px;
    font-size: .45em;
  ` : ``}
`);

const BannerTitle = styled.div`
  color: white;
  line-height: 1.5;
  font-size: 3.5em;
  font-weight: 600;
`;
const BannerSubtitle = styled.div`
  color: white;
  padding-top: 20px;
  font-size: 1.75em;
  font-weight: 600;
`;
const Info = withTheme(styled.div`
  position: absolute;
  top: 20px;
  right: 0;
  a.tel {
    color: white;
    text-decoration: none;
    ${props => props.theme.props.isMobileXs ? `
      width: 50%;
    ` : ``}
  }

  ${props => props.theme.props.isMobile ? `
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    background-color: #555555;
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;
  ` : ``}
`);
const InfoItem = withTheme(styled.div`
  margin-top:20px;
  border: 0px solid rgba(50, 65, 88, 1);
  background-color: rgba(50, 50, 50, 0.83);
  color: white;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  line-height: 1;
  height: 56px;
  font-size: 14px;
  > span {
    font-weight: bold;
    display: inline-block;
    margin-left: 4px;
  }

  ${props => props.theme.props.isMobile ? `
    background-color: #555555;
    border-left: 1px solid #333333;
    margin-top: 0;
    padding-right: 20px;
  ` : `width: 240px;`}

  ${props => props.theme.props.isMobileXs ? `
    width: 50%;
    padding-right: 0;
    `:``}

  &.telItem {
    ${props => props.theme.props.isMobileXs ? `
      width: auto;
      flex-direction: column;
      justify-content: center;
      > div {
        align-items: center;
        display: flex;
      }
    ` : ``}
  }
`);

const SlideContainer = styled.div``;

const EaseSlide = withTheme(styled.div`
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: ${props => props.theme.props.isMobile ? '400px' : '740px'};
    ${props => props.theme.props.isMobileXs ? `
      height: 250px;
    ` : ``}
    background-repeat: no-repeat;
    background-position: center;
  }
  span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
`);

const DownloadLink = styled.a`
  color: inherit;
  text-decoration: none;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

const slideImages = [
  greenHouse,
  blueHouse,
  brownHouse,
];

const Hero = (props) => (
  <Wrapper>
    <SlideContainer>
      <Slide
        easing="ease"
        arrows={false}
        duration="3000"
      >
      {slideImages.map((slide, i)=>(
        <EaseSlide key={`${slide}-${i}`}>
          <div style={{'backgroundImage': `url(${slide})`}}></div>
        </EaseSlide>
      ))}
      </Slide>
    </SlideContainer>
    <HeroContainer>
      <Info>
        <a className="tel" href="tel:+17704452389">
          <InfoItem className="telItem">
            {props.theme.props.isMobileXs ? (
              <div>
              <StyledPhoneIcon />CALL US!
              </div>
            ):(<>
              <StyledPhoneIcon />CALL US!
            </>)}
            <span> 770.445.2389</span>
          </InfoItem>
        </a>
        <InfoItem>
          <DownloadLink href={brochure} target="_blank" download >
            DOWNLOAD BROCHURE <GetAppIcon />
          </DownloadLink>
        </InfoItem>
      </Info>
      <Banner>
        <BannerTitle>BUILDING<br/>QUALITY<br/>PRODUCTS</BannerTitle>
        <BannerSubtitle>SINCE 2012</BannerSubtitle>
      </Banner>
    </HeroContainer>
  </Wrapper>
);

export default withTheme(Hero);
