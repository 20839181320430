import React from 'react';
import styled from 'styled-components';
import { Slide } from 'react-slideshow-image';
import UnstyledCloseIcon from '@material-ui/icons/Close';
import { withTheme } from '@material-ui/core/styles';

const Wrapper = withTheme(styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  top: 350px;
  background-color: #e9e9e9;

  ${props => props.theme.props.isMobile ? `
    overflow-y: auto;
    height: calc(100% - 48px);
  ` : `
    display: flex;
    flex-direction: row;
    position: absolute;
  `}
`);
const Details = withTheme(styled.div`
  background-color: rgb(50, 50, 50);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  ${props => props.theme.props.isMobile ? `
    width: 100%;
    padding: 20px;
  ` : `
    min-width: 525px;
    width: 40%;
    height: 740px;
  `}
`);
const SlideContainer = withTheme(styled.div`
  position: relative;
  ${props => props.theme.props.isMobile ? `
    width: 100%;
  ` : `
    min-width: 300px;
    width: 60%;
  `}
`);
const EaseSlide = withTheme(styled.div`
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: ${props => props.theme.props.isMobile ? '350px' : '740px'};
    background-repeat: no-repeat;
    background-position: center;
  }
  span {
    font-size: 14px;
    text-align: center;
    color: white;
    background: rgb(25 25 25 / 80%);
    align-self: flex-end;
    width: 100%;
    padding: 20px 20px;
  }
`);

const Header = withTheme(styled.div`
  font-weight: 700;
  color: white;
  margin-bottom: 40px;
  text-align: center;

  ${props => props.theme.props.isMobile ? `
    font-size: 18px;
  ` : `
    font-size: 43px;
    width: 330px;
  `}
`);
const Desc = withTheme(styled.div`
  color: white;
  ${props => props.theme.props.isMobile ? `
  ` : `
    width: 400px;
  `}
`);

const Close = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgb(62 62 62 / 0.7);
  padding: 7px;
  line-height: 1;
  z-index: 1;
  cursor: pointer;
`;

const Files = withTheme(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;

  ${props => props.theme.props.isMobileXs ? `
    flex-direction: column;
    width: 100%;
  ` : ``}
`);

const File = withTheme(styled.a`

  background-color: rgba(197, 37, 40, 0.91);
  color: white;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  padding: 15px;
  margin: 0 8px;
  text-decoration: none;

  ${props => props.theme.props.isMobileXs ? `
    width: 100%;
    margin-bottom: 16px;
  ` : `
    height: 100px;
    width: 100px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
      transition: ease-out background-color 300ms, font-size 300ms;
      background-color: #4f4b4b;
      font-size: 16px;
    }
  `}


`);

const CloseIcon = styled(UnstyledCloseIcon)`
  && {
      font-size: 40px;
      color: white;
    }
`;

const ProductDetail = ({ onClose, product, theme }) => (
  <Wrapper>
  {theme.props.isMobile ? (
    <>
    <SlideContainer>
    {(product.slideImages.length > 1) ? (
      <Slide
        easing="ease"
        duration="3000"
        autoplay={product.slideImages.length > 1 ? true : false}
        arrows={product.slideImages.length > 1 ? true : false}
      >
      {product.slideImages.map((img) => (
        <EaseSlide>
          <div style={{
            'backgroundImage': `url(${img.url})`,
            ...img.styles
          }}>
            {img.text && <span>{img.text}</span>}
          </div>
        </EaseSlide>
      ))}
      </Slide>
    ) : (
      <EaseSlide>
        <div style={{
          'backgroundImage': `url(${product.slideImages[0].url})`,
          ...product.slideImages[0].styles
        }}>
          {product.slideImages[0].text && <span>{product.slideImages[0].text}</span>}
        </div>
      </EaseSlide>
    )}
    </SlideContainer>
    <Details>
      <Header>{product.name}</Header>
      <Desc>{product.description}</Desc>
      <Files>
        {product.pdf.map((pdf) => (
          <File href={pdf.file} target="_blank"><span>{pdf.name}</span></File>
        ))}
      </Files>
    </Details>
    </>
    )
    : (
      <>
      <Details>
        <Header>{product.name}</Header>
        <Desc>{product.description}</Desc>
        <Files>
          {product.pdf.map((pdf) => (
            <File href={pdf.file} target="_blank"><span>{pdf.name}</span></File>
          ))}
        </Files>
      </Details>
      <SlideContainer>
        <Close onClick={onClose} ><CloseIcon /></Close>
        {(product.slideImages.length > 1) ? (
          <Slide
            easing="ease"
            duration="3000"
            autoplay={product.slideImages.length > 1 ? true : false}
            arrows={product.slideImages.length > 1 ? true : false}
          >
          {product.slideImages.map((img) => (
            <EaseSlide>
              <div style={{
                'backgroundImage': `url(${img.url})`,
                ...img.styles
              }}>
                {img.text && <span>{img.text}</span>}
              </div>
            </EaseSlide>
          ))}
          </Slide>
        ) : (
          <EaseSlide>
            <div style={{
              'backgroundImage': `url(${product.slideImages[0].url})`,
              ...product.slideImages[0].styles
            }}>
              {product.slideImages[0].text && <span>{product.slideImages[0].text}</span>}
            </div>
          </EaseSlide>
        )}

      </SlideContainer>
      </>
    )}
  </Wrapper>
);
export default withTheme(ProductDetail);
